import React, {useState, useEffect} from 'react'
import getEndpoint from '../utils/getEndpoint'
import axios from 'axios'
import './PlatformConvert.scss'
let REDEEM = getEndpoint('redeem')

export default function Conversion ({phone, shopId}) {
  const [inputPhone, setInputPhone] = useState(phone)
  const [message, setMessage] = useState(null)
  useEffect(function track () {
    axios.post(REDEEM, {
      shopId: shopId,
      action: "click-link",
      originalPhone: phone,
      preferredPhone: phone,
    })
  }, [0])

  function submit (event) {
    event.preventDefault()
    axios.post(REDEEM, {
      shopId: shopId,
      action: "redeem",
      originalPhone: phone,
      preferredPhone: phone,
    }).then((res) => {
      setMessage(res.data.message)
    }).catch((e) => {
      console.log(e)
    })

  }



  return(
    <div className="rewards-special">
      <article className="">

        <h1 className="header">
          <span>Here's $10</span>
          {/* <br/>
          <small>pick-up / delivery</small> */}
        </h1>
        <div className="card">
          <p className="instructions">
          <strong>  Instructions:</strong> After sign-up, use your phone number during checkout to apply the credit.
          </p>

          {!message ? <form onSubmit={submit} action="/" className="sign-up-form">
            <input type="text" value={inputPhone} disabled={true} />
            <button type={"submit"} className="sign-up-button">Sign me up!</button>
          </form> :
          <p className="message">{message}</p>}

          <h2>More reasons to sign up</h2>
          <ul>
            <li className="instructions">All direct orders enjoy a <strong>5% cashback</strong> towards future orders</li>

            <li className="instructions">Weekly chance to earn extra cashback.</li>

            <li className="instructions">
              <strong>Ordering directly</strong> thru us gets you priority service and delivery.
            </li>

            <li className="instructions">
              <strong>No service fee.</strong> Some customers see a service fee for Grubhub and Doordash. We never do that!
            </li>
          </ul>
        </div>
      </article>
    </div>
  )
}

import React from 'react'
import {withRouter} from 'react-router-dom'
import { Subscribe } from 'unstated';
import CartContainer from './CartContainer'
import {injectShopContainer} from './ShopDataContainer'


class CartAddToCartButton extends React.Component {
  onAddToCart = (...args) => {
    // console.log(this.props)
    let cartItemId = this.props.onAddToCart(...args)
    this.props.history.push(`/cart/${cartItemId}`)
  }
  render() {

    return(
      <div className="add-to-cart-button">
        <div className="choose-type">
          <button onClick={this.onAddToCart.bind(this, this.props.product, null)}>Add</button>
        </div>



      </div>
        )


  }
}

const CartAddToCartButtonWithRouter = injectShopContainer(withRouter(CartAddToCartButton))

function AddToCartButton({product}) {

  return (
    <Subscribe to={[CartContainer]}>
      {(cart) => {

      return(
        <CartAddToCartButtonWithRouter
          type={cart.state.cartType}
          onAddToCart={(_, cartType) => cart.addToCart(product, cartType)}
          onSwitchToPickup={() => cart.setCartType('PICKUP')}
          onSwitchToDelivery={() => cart.setCartType('DELIVERY')}
        />
      )}}
    </Subscribe>
  );
}

export default (AddToCartButton)

import React from 'react'
import {withRouter} from 'react-router-dom'
import {Route, Switch, Link} from 'react-router-dom'
import MenuCategoryListView from './MenuCategoryListView'
import openPhotoSwipe from './openPhotoSwipeGallery'
import {Consumer} from './ShopDataContainer'
import AutoMenuNavigator from './AutoMenuNavigatorTwo'
import './AutoMenuTwo.scss'
import ScrollToTop from './ScrollToTop'


class AutoMenu extends React.PureComponent {
  constructor() {
    super();
    let records = []

    this.state = { menu: records, info: {}, galleryIsOpen: false };
    this.openPhotoSwipeGallery = this.openPhotoSwipeGallery.bind(this)
    this.onAddCurrentSlideToCart = this.onAddCurrentSlideToCart.bind(this)

    this.focusRef = React.createRef()
  }

  componentDidMount () {
    let productIdToFocus = null
    try {
      productIdToFocus = window['product-id-to-focus']
      // console.log(productIdToFocus)
    } catch(e) {}

    if (!productIdToFocus && this.props.shouldFocus) {
      try {
        console.log('focusing to menu')
        this.focusRef.current.focus()
      } catch(e) {
      }
    }

    if (productIdToFocus && this.props.shouldFocus) {
      console.log('🐞 focusing: ' + productIdToFocus)
      try { window['product-id-to-focus'] = null } catch(e) {}
      try {

        const productEle = document.querySelector(`#p_${productIdToFocus}`)
        productEle.setAttribute('tabindex', '-1')
        productEle.focus()
        productEle.scrollIntoView()
      } catch(e) {
        console.log(e)
      }
    }
  }

  onAddCurrentSlideToCart() {
    // console.log('clicked')
    if (!this.gallery) { return }
    let product = this.gallery.currItem && this.gallery.currItem.product
    // let cartItemId = global.__addToCart(product)
    this.gallery.close()
    this.props.history.push(`/products/${product.id}`)

  }
  openPhotoSwipeGallery(productId) {
    let allProducts = []
    this.props.menu.subMenus.forEach(({products}) => allProducts = [...allProducts, ...products])
    let gallery = openPhotoSwipe(productId, allProducts)
    this.gallery = gallery
    this.setState({ galleryIsOpen: true })
    gallery.listen('close', () => {
      this.setState({ galleryIsOpen: false })
      this.gallery = null
    })

  }

  render() {
    console.log('rendering long ass menu')

    let {menu, settings, hideNav, noPhoto, categoryId} = this.props
   
    // console.log(match)
    settings = settings || {}

    if (!menu || !menu.subMenus) {return null}

    const {name, address} = {}

    let subMenus = menu.subMenus
    // filter based on ids
    if (this.props.categorysToShow) {
      subMenus = subMenus.filter(({id}) => {
        return (this.props.categorysToShow.indexOf(id) > -1)
      })
    }
    // filter based on name
    if (this.props.legacy__namesToShow) {
      subMenus = subMenus.filter(({name}) => {
        return (this.props.legacy__namesToShow.indexOf(name) > -1)
      })
    }

    if (this.props.tagsToShow) {
      subMenus = subMenus.filter(({tag}) => {
        if (!tag) { return false}
        let hasTag = false
        this.props.tagsToShow.forEach((tagToShow) => {
          hasTag = tag.indexOf(tagToShow) !== -1
          if (hasTag) { return false }
        })
        return hasTag
      })
    }

    if (this.props.subMenuFilter) {
      subMenus = subMenus.filter(this.props.subMenuFilter)
    }
    if (this.props.subMenuDisableFilter) {
      subMenus = subMenus.map(this.props.subMenuDisableFilter)
    }

    if (this.props.shopId) {
      subMenus = subMenus.filter(({shopId}) => {
        return this.props.shopId === shopId
      })
    }
    // console.log('noPhoto', noPhoto)


    return (<div className="menu-story" role="main">

      <header
        style={{
          opacity: 0,
          height: 0
        }}

        >

        <h1
          tabIndex={-1}
          ref={this.focusRef}
          >Ordering Menu</h1>
      </header>

        <div className="search-bar">

        </div>

        {!categoryId && <AutoMenuNavigator
          subMenus={subMenus}
        />}

        
  

      <section>
        {subMenus
            .filter((subMenu) => {
              if (categoryId) {
                return subMenu.id === categoryId
              } else {
                return false
              }
              const tag = subMenu.tag || ''
              if (tag.indexOf('hide') !== -1) {
                return false
              }
              return true
            })
            .map(({id, name, products, description, disableOrder, tag}) => {
            if (this.props.disableOrder) {
              disableOrder = true
            }
            if (this.props.productFilter) {
              products = products.filter((product) => {
                return this.props.productFilter(product, {name})
              })
            }
            const tagCSS = tag ? ` ${tag}` : ""
            return(
              <div className={"category" + tagCSS} key={name && name.en}>
                <ScrollToTop></ScrollToTop>

                <header className="category__header">
                  <div className="back-button">
                    <Link to={"/house"}>
                      <BackSVG
                      style={{
                        width: "30px",
                        fill: "var(--blue-color)"
                      }} />
                    </Link>
                  </div>

                  <h3
                    id={`menu-category-${id}`}
                    className="category-name">
                    <span className="text">{name && name.en}</span>
                  </h3>

                
                </header>

                {description && <p className="category__description">
                    {description && description.en}
                  </p>}



                <MenuCategoryListView
                  altStyleA
                  categoryName={name && name.en}
                  disableOrder={disableOrder}
                  noPhoto={noPhoto}
                  items={products}
                  width={this.props.width || 180}
                  openPhotoSwipeGallery={this.openPhotoSwipeGallery}
                />

              </div>
            )
          })
        }



      </section>

      {/* {this.state.galleryIsOpen && <PhotoswipePortal
        onAddCurrentSlideToCart={this.onAddCurrentSlideToCart}
      />} */}
      {this.state.galleryIsOpen && <div className="test-test">
        <button onClick={this.onAddCurrentSlideToCart}>Add To Cart</button>
      </div>}
    </div>)


  }
}

// const AutoMenuWithRouter = withRouter(AutoMenu)
const AutoMenuWithRouter = withRouter(AutoMenu)
const WithContext = (props) => {
  return(
    <Consumer>
      {({menu, settings}) => {
        return(<AutoMenuWithRouter {...props} menu={menu} settings={settings}></AutoMenuWithRouter>)
      }}
    </Consumer>
  )
}


const BackSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 358.39 358.39"
    {...props}
  >
    <path d="M179.19 0c-47.52 0-93.1 18.88-126.71 52.49C18.88 86.1 0 131.68 0 179.2s18.88 93.11 52.48 126.71c33.61 33.61 79.19 52.48 126.71 52.48s93.11-18.88 126.71-52.48a179.189 179.189 0 0 0 52.48-126.71c0-31.46-8.28-62.36-24.01-89.6a179.247 179.247 0 0 0-65.59-65.59A179.13 179.13 0 0 0 179.19 0Zm0 313.61c-35.64 0-69.83-14.16-95.04-39.37s-39.36-59.39-39.36-95.04 14.16-69.83 39.36-95.04 59.39-39.36 95.04-39.36 69.83 14.16 95.04 39.36 39.36 59.39 39.36 95.04-14.16 69.83-39.36 95.04-59.39 39.36-95.04 39.36Z" />
    <path d="M246.39 156.81h-80.3l28-28A23.236 23.236 0 0 0 196 97.06a22.416 22.416 0 0 0-16.05-7.42 22.383 22.383 0 0 0-16.43 6.53l-48.11 48.1c-9.28 9.27-14.49 21.85-14.49 34.97s5.21 25.7 14.49 34.97l46.98 47.15a23.25 23.25 0 0 0 15.51 7.12c5.88.32 11.67-1.6 16.19-5.39 4.57-4.11 7.26-9.91 7.43-16.05.17-6.14-2.19-12.08-6.53-16.43l-28.9-29.01h80.3c5.94 0 11.64-2.36 15.84-6.56a22.405 22.405 0 0 0 0-31.68c-4.2-4.2-9.9-6.56-15.84-6.56Z" />
  </svg>
)

export default WithContext



import React from 'react'
import {getRestaurantId} from "../utils"
import getCustomComponet from './getCustomComponent'

const components = getCustomComponet(getRestaurantId())

const CustomComponentsContext = React.createContext({})
const Provider = CustomComponentsContext.Provider
const Consumer = CustomComponentsContext.Consumer

class CustomComponentsProvider extends React.Component {

  render() {
    return(<Provider value={{
      customComponents: components,
      locationId: this.props.locationId
    }}>
      {this.props.children}
    </Provider>)
  }
}


export default CustomComponentsProvider
export function injectCustomComponents (Child) {
  return (props) => (
    <Consumer>
      {({customComponents, locationId}) => {
        return(<Child {...props}
          locationId={locationId}
          customComponents={customComponents}></Child>)
      }}
    </Consumer>
  )
}
export {Consumer}

import React, {useState, useContext} from 'react'



const ThemeContext = React.createContext({
  themeName: ''
})


// function ThemedApp (props) {
//   var preferredTheme = '';
//   try {
//     preferredTheme = localStorage.getItem('theme');
//   } catch (err) { }
//
//   const [theme, setTheme] = useState(preferredTheme)
//
//   window.__preferredTheme = theme
//   window.__setPreferredTheme = function (newTheme) {
//     console.log('setting: ', newTheme)
//     setTheme(newTheme)
//     try {
//       localStorage.setItem('theme', newTheme);
//     } catch (err) {}
//   }
//
//
//   return(
//     <ThemeContext.Provider value={{
//       themeName: theme
//     }}>
//       {props.children}
//     </ThemeContext.Provider>
//   )
// }

function injectThemeContext(Child) {
  return (props) => {
    const themeData = useContext(ThemeContext)

    return(<Child {...props} {...themeData} />)
  }
}

export default ThemeContext

import {getRestaurantId} from '../utils'
import getEndpoint from '../utils/getEndpoint'

const IS_ONLINE_ENDPOINT = getEndpoint("is-ordering-online")
// console.log('is online endpoint', IS_ONLINE_ENDPOINT)

const isOrderingOnline = (callback) => {

  let restId = getRestaurantId()

  if (restId === 'picopicarico') {
    callback(true);
    return
  }

  var request = new XMLHttpRequest();
  request.open('GET', `${IS_ONLINE_ENDPOINT}?r_id=${restId}`, true);

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      // Success!
      // console.log('online')
      callback(true)
    } else {
      // We reached our target server, but it returned an error
      callback(false)
    }
  };

  request.onerror = function() {
    // There was a connection error of some sort
  };

  request.send();
  }

export default isOrderingOnline

import React, {useState, useEffect, useMemo} from 'react'
import { Provider } from 'unstated'

import getEndpoint from '../utils/getEndpoint'
import CartContainer from '../react/CartContainer'

// urban press only
import applyDiscount from './applyDiscount'
import unzipMenu from './unzipMenu'

import axiosRetry from 'axios-retry';
import axios from 'axios';
axiosRetry(axios, { retries: 5, retryDelay: (retryCount) => { return retryCount * 1000} });

const CASHBACK_ENDPOINT = getEndpoint('get-cashback')

const ShopDataContext = React.createContext({})
const Consumer = ShopDataContext.Consumer


function ShopDataContainer ({ restId, children, preloadMenuData }) {
  // console.log('preload: ', preloadMenuData)
  let menuURL = `https://afoodapart.com/api/menu/${restId}`
  // if (restId === 'pablitostacosburbank') {
  //   menuURL = "https://fair-quilt-chicken.glitch.me"
  // }
  // let menuURL = `https://afa-menu-db.afoodapart.workers.dev/api/menu/${restId}`
  //https://us-central1-avocadoftw-c5639.cloudfunctions.net/v1-getShop?shopId=thepark&showDraft=false&allProductAttributes=false
  // if (restId === 'phoburbank') {menuURL = `/data/phoburbank.json`}
  // if (restId === 'aleppokitchen') {menuURL = `/data/aleppokitchen.json`}
  // if (restId === 'phoseason') {menuURL = `/data/phoseason.json`}
  // if (restId === 'tortas') {menuURL = `/data/tortas.json`}
  // if (restId === 'beachwood') {menuURL = `/data/beachwood.json`}
  // if (restId === 'thepark') {menuURL = `/data/thepark.json`}
  // if (restId === 'westernwok') {menuURL = `/data/westernwok.json`}
  // if (restId === 'elcriollo') {menuURL = `/data/elcriollo.json`}
  // if (restId === 'coralcafe') {menuURL = `/data/coralcafe.json`}
  // if (restId === 'mrkabob') {menuURL = `/data/mrkabob.json`}
  // if (restId === 'siri') {menuURL = `/data/siri.json`}
  // if (restId ==='cravestudiocity') {
  //   menuURL = `https://afoodapart.com/api/menu/craveholiday`
  // }
  if (restId ==='myfishstopnoho') {
    menuURL = `https://afoodapart.com/api/menu/myfishstop`
  }
  if (restId === 'liquorpalace') { menuURL = `https://9ph5dsn9wc.execute-api.us-west-1.amazonaws.com/v6-multishop/shop/?r_id=liquorpalace__pablitos`}
  let settingsURL = `${getEndpoint('settings')}?r_id=${restId}`
  if (restId === 'tanghuo') {
    settingsURL = `${getEndpoint('settings')}?r_id=${restId}&v=3`
  }
  if (restId === 'twindragonxmas') {
    menuURL = `https://afoodapart.com/api/menu/twindragon`
  }
  if (restId === 'mikazaexpress') {
    menuURL = `https://afoodapart.com/api/menu/pablitosworld`
  }
  if (restId === 'hamburguetza') {
    menuURL = `https://afoodapart.com/api/menu/pablitostacosnoho`
    settingsURL = `${getEndpoint('settings')}?r_id=pablitostacosnoho`
  }
  // let settingsURL = `${getEndpoint('settings')}?r_id=${restId}&v=1`
  const [settings, setSettings] = useState(null)
  const [menu, setMenu] = useState(preloadMenuData)
  useEffect(function fetchData () {
    axios.get(settingsURL)
      .then((response) => {
        setSettings(response.data.text)
      })
    if (!preloadMenuData) {
      axios.get(menuURL)
      .then((response) => {
        // console.log("response:", response)
        setMenu(
          applyDiscount(
            unzipMenu(
              response.data.menu
            )
          )
          // applyDiscount(JSON.parse(response.data.fields.shop.stringValue).menu)
        )
      })
    }
  },
  restId) // re-fetch only when restId changes


  let cartContainer = useMemo(() => new CartContainer({ shopId: restId, settings }), [restId, settings])

  return(
    <Provider inject={[cartContainer]}>
      <ShopDataContext.Provider value={{menu: menu, settings: settings}}>
        {children}
      </ShopDataContext.Provider>
    </Provider>
  )
}


export default ShopDataContainer
export function injectShopContainer (Child) {
  return (props) => (
    <ShopDataContext.Consumer>
      {({menu, settings}) => {
        if (!menu || !settings) { return (<div className="loading"> </div>) }
        return(<Child {...props} shop={menu} settings={settings}></Child>)
      }}
    </ShopDataContext.Consumer>
  )
}
export function ensureShopContainer (Child) {
  return (props) => (
    <ShopDataContext.Consumer>
      {({menu, settings}) => {
        if (!menu || !settings) { return (<div className="loading"> </div>) }
        return(<Child {...props} shop={menu} settings={settings}></Child>)
      }}
    </ShopDataContext.Consumer>
  )
}


export {Consumer, ShopDataContext}

import React, { Component } from 'react';
import {injectCartContainer} from '../react/CartContainer'
import {injectShopContainer} from '../react/ShopDataContainer'
import {format} from 'date-fns/esm'
import ErrorBoundary from '../utils/ErrorBoundary'
import logEvent from '../utils/logEvent'

import './DineInOptions.scss'


function DineInOptions (props) {
  // "Make Now - I'm here" Versus "as soon as possible"
  const cart = props.cart
  return(
    <div className="dine-in-option">
      {/* <label
        className="dine-in-option-label"
        htmlFor="dine-in-now">
        <input
          className="dine-in-radio"
          name="dine-in-option"
          id="dine-in-now"
          type="radio"
          checked={cart.state.dineInOption === 'DINE_NOW'}
          onClick={cart.setStateAndSave.bind(this, {dineInOption: 'DINE_NOW'})}
        />
        I'm Here Now <div className="find-table"></div>
      </label> */}

      {/* <div>You must be </div> */}


      <div className="tab-number-ui">
        <label htmlFor="tab-number-input">Tab Number:</label>
        <input
          value={cart.state.tabNumber}
          onChange={(e) => {
            cart.setStateAndSave({ tabNumber: e.target.value })
          }}
          id="tab-number-input" type="text"/>
      </div>



      {/* <label
        className="dine-in-option-label"
        htmlFor="dine-in-later">
        <input
          checked={cart.state.dineInOption === 'DINE_LATER'}
          onClick={cart.setStateAndSave.bind(this, {dineInOption: 'DINE_LATER'})}
          className="dine-in-radio"
          name="dine-in-option"
          id="dine-in-later"
          type="radio"
          value="DINE_LATER"
        />
        Make Food when I Arrive
      </label> */}
    </div>
  )
}

// export default DineInOptions
export default injectCartContainer(DineInOptions)

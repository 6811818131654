import React from 'react'
import './sections.scss'

export const sections = [
  {
    h1: "About Us",
    sub: "Brick Oven Pizza & Others",
    img: "https://afag.imgix.net/romancing-the-bean-cafe/krull.png?w=800",
    content: "Krull spent months perfecting her menu and uses only the freshest organic ingredients she can find, including from local farmers markets.  To add a flare to her new space, Kerry called on her daughter, Kendra, whose design skills has transformed the café into a romantic setting featuring an old yet modern elegance.  With a hammer in hand, Kendra built the shelving units, painted the walls, and completely designed the newly reconstructed café that is Romancing the Bean.  When the doors opened, Kerry’s son, Ramsey, helped set up their computer system and filled in as barista until new employees were added. ",
    link: "/menu",
    className: " visit-us",

  },
  {
    h1: "The Cafe",
    // link: "/the-brick-",
    // sub: "Live Music thursday - saturday",
    // link: "/cocktails",
    // linkText: "Cocktail Menu",
    img: "https://afag.imgix.net/romancing-the-bean-cafe/interior.jpg?w=800",
    content: `This café/coffee house is a southern California gem built on hard work, family, and love.  And family doesn’t just mean Kerry and her kids, the Romancing the Bean family has now grown to include three chefs, a number of baristas and servers, and of course the wonderful and supportive customers that followed the Krull family through their journey to become the Daily News Readers Favorite Choice "Coffee shop” 2013 and the Burbank Leader’s “Best of Burbank” 2013.`
    // link: "/events",
    // linkText: "View Schedule"
  },


  // {
  //   h1: "Salads",
  //   sub: "gather with friends",
  //   img: "https://afag.imgix.net/lancers-family-restaurant/salads.jpg?w=800",
  //   content: ""
  // },
  // {
  //   h1: "Breakfast",
  //   sub: "gather with friends",
  //   img: "https://afag.imgix.net/lancers-family-restaurant/breakfast.jpg?w=800",
  //   content: ""
  // },
  // {
  //   h1: "Coffee",
  //   sub: "gather with friends",
  //   img: "https://afag.imgix.net/lancers-family-restaurant/coffee.jpeg?w=800",
  //   content: ""
  // },
  //
  // {
  //   h1: "Lunch",
  //   img: "https://afag.imgix.net/lancers-family-restaurant/lunch.jpg?w=800",
  //   // hero: "https://afag.imgix.net/harvest-moon/in-school-program.jpg?w=1200",
  //   // link: "/about-us/in-school-program",
  //   // linkText: "details",
  //   // pageContentComponent: function () {
  //   //   return(
  //   //     <article>
  //   //       <p>Harvest Moon is an innovator in School Lunch programs. Drawing upon her extensive experience as a corporate executive chef, Christine Drapkin knows how to make school dining a delicious, non-repetitive, and exciting experience. She has re-invented the school lunch program to be so much more than the daily food tray.</p>
  //   //       <p>Her philosophy is simple: Don’t underestimate your children. Serve them great tasting, nutritious, fresh food and teach them where it comes from. You will see them cultivate their own curiosity for great food and a desire to eat well.</p>
  //   //       <p>As a mother and expert on feeding children, Christine gained expertise at her own table. “My daughter Sydney went to farmers markets, food stores and restaurants when she was in her stroller. We’d grind her food in a small plastic food mill, and she ate what we did. I knew that by having her taste everything, early on, she’d learn to appreciate fresh, healthy food, and my hunch was right.”</p>
  //   //       <p>Harvest Moon expertly provides highly nutritious, organic, and tasty school lunches. Christine, or Chef Chris as she is known in school kitchens, has launched and managed daily food programs for schools in LA since 2007. For more information, contact chris@harvestmoonco.com</p>
  //   //     </article>
  //   //   )
  //   // }
  // },
  // {
  //   h1: "Chef Ricky",
  //   sub: "romatic dinner date",
  //   link: "/about",
  //   linkText: "About our Kitchen",
  //   img: "https://afag.imgix.net/lancers-family-restaurant/chef-ricky.jpg?w=800",
  //   content: ""
  //
  // },
]

export const sectionPagesDef = sections.map(({ link, linkText, h1: heading, full, hero, gallery=[], pageContentComponent }) => {
  if (!linkText) {return null}
  // if (!)
  return {
    path: link,
    hidden: true,
    component: () => (
      <div className="experience page">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundSize: "fill",
            backgroundPosition: "center"
          }}
          className="header">
          <div className="text">
            <h1>{heading}</h1>
          </div>
        </div>
        {/* <div className="mini-banner">
          <img
            src="http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomspecialsTAB-1-900x97.jpg"
            alt=""/>
        </div> */}
        <div className="content">

          {pageContentComponent() || <p>{full}</p>}
        </div>

        <div className="gallery">
          {gallery.map((url) => {
            return(
              <div className="item">
                <img src={url} alt=""/>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}).filter((a) => !!a)

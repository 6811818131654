import React from 'react'
import Photo from '../../react/Photo'
import Price from '../Price'
import './PizzaToppings.scss'


export default function PizzaToppings (props) {
  const {
    pizzaSections=1,
    modifiers=[],
    editCustomerChoice,
    customerPicks
  } = props

  let sections = [{
    label: "Whole"
  }, {
    label: "Half & Half", picked: "picked"
  }]
  

  // let options = {}
  // modifiers.forEach(({id}) => options[id] = false)
  return (<div className="product-modifiers-2">
    <div className="product-modifiers">
      <fieldset>
        <legend className="modifier-heading-lvl-2">Choose Sections</legend>
      {/* Choose How Many Sections */}
      {sections.map((section) => {
        return(
      
            <div className={"modifier " + section.picked } >
              <input type="checkbox" name="" id="" />
              <label htmlFor="">
                {section.label}
      
              </label>
            </div>
        )
      })}
      </fieldset>
    </div>

    <fieldset>
      <legend className="modifier-heading-lvl-2">Add Toppings</legend>
      <div className="toppings-table">
        <table>
          <thead>
            <tr>
              
              <td></td>
              <td>Whole<span className="price">$2.75</span></td>
              <td>1/2<span className="price">$2.75</span></td>
              <td>1/2<span className="price">$2.75</span></td>
            </tr>
          </thead>
          <tbody>
            {modifiers.map(modifier => {
              return(
                <tr>
                  <td className="topping-name">{modifier.name}</td>
                  <td> <input type="checkbox" name="" id="" /> </td>
                  <td> <input type="checkbox" name="" id="" /> </td>
                  <td> <input type="checkbox" name="" id="" /> </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </fieldset>

    

    
  </div>)
}

import React, { useState } from 'react';
import {injectShopContainer} from '../react/ShopDataContainer'
import './site-notice.scss'
import DriveIn from '../Custom/utils/DriveIn/DriveIn'
function SiteNotice (props) {
  // console.log('site notice!')

  const [hideNotice, setHideNoticeState] = useState(window['hide-notice-state'] || true)
  function setHideNotice (state) {
    setHideNoticeState(state)
    window['hide-notice-state'] = state
  }

    const holidayNotice =
      (props.settings &&
      props.settings.news &&
      props.settings.news.holiday) || props.forceShow
    const contactFreeOptions =
      (props.settings &&
      props.settings.contactFreeOptions) || props.forceShow
    if (!holidayNotice && !contactFreeOptions) return(null)
    // if (holidayNotice.length < 2) return (null)
    if (hideNotice) {
      return (
        <div className="site-notice-wrapper">
          <p className="hide-button-wrap">
            {holidayNotice && (holidayNotice.length > 1) && <div className="bg"><article className="notice">
                <p><span className="custom-notice">{holidayNotice}</span></p>

              </article>
              </div>}
            {contactFreeOptions && <a href="#" className="view" onClick={() => setHideNotice(false)}>contact-free options</a>}
          </p>
        </div>
      )
    }
    return (
      <div className="site-notice-wrapper">
        <div className="bg">
          {/* <h1><span>Update</span></h1> */}
          <article className="notice">
            <p><span className="custom-notice">{holidayNotice}</span></p>

          </article>
          {contactFreeOptions && <DriveIn
            setHideNotice={setHideNotice}
            orderLink={props.orderLink}
            ></DriveIn>}

            <p className="hide-button-wrap">

              <a href="#" onClick={() => setHideNotice(true)}>hide</a>
            </p>

        </div>
      </div>
    );

}

export default injectShopContainer(SiteNotice);
export {SiteNotice}

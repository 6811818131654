import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import {injectShopContainer} from '../react/ShopDataContainer'
import {getRestaurantId} from '../utils'

const templates = [
  { dw: 375, dh: 812, ratio: 3 }, // iPhone X
  { dw: 414, dh: 896, ratio: 3 }, // iPhone XS Max
  { dw: 414, dh: 896, ratio: 2 }, // iPhone XR
  { dw: 375, dh: 667, ratio: 2 }, // iPhone 8, 7, 6s, 6
  { dw: 414, dh: 736, ratio: 3 }, // iphone Plus
  { dw: 320, dh: 568, ratio: 2 }, // iphone 5, SE
  { dw: 768, dh: 1024, ratio: 2 }, // iPad mini / Air
  { dw: 834, dh: 1112, ratio: 2 }, // iPad Pro 10.5
  { dw: 1024, dh: 1366, ratio: 2 }, // iPad Pro 12.9 1st/2nd gen
  { dw: 834, dh: 1194, ratio: 2 }, // iPad Pro 11 edge-to-edge
  { dw: 1024, dh: 1336, ratio: 2 }, // iPad Pro 12.9 edge-to-edge
]

class AppleStartUpImageHeaders extends Component {
  render() {
    return (
      <Helmet>
        {/* <!-- iPhone X (1125px x 2436px) --> */}
        <link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" href="/apple-launch-1125x2436.png"/>
        {/* <!-- iPhone 8, 7, 6s, 6 (750px x 1334px) --> */}
        <link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href="https://d2abh92eaqfsaa.cloudfront.net/image/fetch/c_fill,w_750,h_1443/https://dl.airtable.com/U7YWOdedTzudsb1hpuhw_DSCF5289.jpg"/>
        {/* <!-- iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px) --> */}
        <link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)" href="https://d2abh92eaqfsaa.cloudfront.net/image/fetch/c_fill,w_640,h_1136/https://dl.airtable.com/U7YWOdedTzudsb1hpuhw_DSCF5289.jpg"/>
        {/* <!-- iPhone 5 (640px x 1136px) --> */}
        <link rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          href="https://d2abh92eaqfsaa.cloudfront.net/image/fetch/c_fill,w_640,h_1136/https://dl.airtable.com/GTNBdvhMQ56JNNftthp3_DSCF1042.jpg"/>
        {/* <!-- iPad Mini, Air (1536px x 2048px) --> */}
        <link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)" href="/apple-launch-1536x2048.png"/>
        {/* <!-- iPad Pro 10.5" (1668px x 2224px) --> */}
        <link rel="apple-touch-startup-image" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)" href="/apple-launch-1668x2224.png"/>
        {/* <!-- iPad Pro 12.9" (2048px x 2732px) --> */}
        <link rel="apple-touch-startup-image" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)" href="/apple-launch-2048x2732.png"/>
      </Helmet>
    );
  }
}

export default injectShopContainer(AppleStartUpImageHeaders);

import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
class OrderScrollManager extends Component {

  getSnapshotBeforeUpdate = (prevProps, prevState) => {

    if (prevProps.location.pathname === this.props.orderPath) {
      // save xy if navigating away from '/'
      console.log('😆 saving order loc')
      window[`order-scroll-${this.props.orderPath}`] = [window.scrollX, window.scrollY]
    }
    return null
  }

  componentDidUpdate = (prevProps, prevState) => {
    const destLoc = this.props.location.pathname
    const departureLoc = prevProps.location.pathname

    // scroll to top if user clicked on home link from home page
    if (departureLoc === this.props.orderPath) {
      window.scrollTo(0,0)
      return
    }

    if (destLoc === this.props.orderPath && window[`order-scroll-${this.props.orderPath}`]) {
      console.log('😆 restoring order loc')
      const [left, top] = window[`order-scroll-${this.props.orderPath}`]
      console.log('scrolling back to:', top, left)
      window.scrollTo({
        top, left, behavior: 'auto'
      })
    }
    // prevProps.
  }

  render() {
    console.log('order scroll manager rendered')
    return null
  }
}

export default withRouter(OrderScrollManager);

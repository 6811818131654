import cloudinary from 'cloudinary-core'
import React from 'react'

var cl = cloudinary.Cloudinary.new({cloud_name: 'dgps1radv'});

const Image = (props) => {
  let {width} = props

  let cloudinaryURL = makeURL(props)

  return (<img src={cloudinaryURL} {...props}/>)
}

function makeURL ({src, width=70}) {
  return cl.url(src, {width, crop: "fill",  type: 'fetch'})
}

export default Image
export {makeURL}

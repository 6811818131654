import React from 'react'
import {Redirect} from 'react-router-dom'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {

    try {window.Sentry.captureException(error)} catch (e) {console.log(e)}
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(this.props.name, error, info);
  }

  render() {
    // console.log('rendering error boun')
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Redirect to={this.props.to || '/'}></Redirect>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary

import React from 'react'
import {CardElement, 
  useStripe, useElements} from '@stripe/react-stripe-js';
// import InjectedCCForm from './CCForm'
// import InjectedPaymentRequestForm from './PaymentRequestForm'

function StripePayment () {


  const CARD_OPTIONS = {

    style: {base: {fontSize: '18px'}}
  }

    return (

        <div className="payment-container">

          <div>
            {/* <AddressSection></AddressSection> */}


            <CardElement
              onChange={(changeObject) => { console.log(changeObject) }}
              options={CARD_OPTIONS}
            />

            {/* secure checkout by <a href="https://stripe.com">Stripe</a> */}
          </div>





        </div>

    );

}

export default StripePayment


import makeCloudinaryUrlForPhoto from '../afa-photo'
import getCustomComponent from '../Custom/getCustomComponent'

// let imgixGlobalOptions = {}
const components = getCustomComponent && getCustomComponent()
const imgixGlobalOptions = (components && components.imgixGlobalOptions) || {}

// console.log(imgixGlobalOptions)

export default function makeCloudinaryUrlForPhotoWithCustomOptions (photo, opts) {
  return makeCloudinaryUrlForPhoto(photo, opts, imgixGlobalOptions)
}

// turn google placeId returned "addressComponents" into postal address components

export function geocodeAddressComponentsToNinja (addressComponents) {
  const requriedTypes = [
    "street_number", "route", "locality", "administrative_area_level_1", "postal_code"
  ]
  const components = {}
  requriedTypes.forEach((type) => {
    const component = addressComponents.find((comp) => {
      if (comp.types.includes(type)) { return true }
    })
    if (!component && ["street_number", "locality"].includes(type)) {
      throw new Error(`Cannot find postal address for location. (no ${type} info)`)
    }
    components[type] = component || null
  })
  const {street_number, route, locality, administrative_area_level_1, postal_code} = components
  const DestinationAddress1 = `${street_number.long_name} ${route.long_name}`
  const DestinationCity =     locality.long_name
  const DestinationStateId =  administrative_area_level_1.short_name
  const DestinationZipcode =  postal_code.long_name
  const addressString = `${DestinationAddress1}, ${DestinationCity}, ${DestinationZipcode}`
  return ({
      DestinationAddress1,
      DestinationCity,
      DestinationStateId,
      DestinationZipcode,
      addressString
    })
}

import {useReducer, useState} from 'react'

export default function useEditProduct (product) {
  const [state, dispatch] = useReducer(reducer, product, init)

  const helpers = {
    increment: () => dispatch({ type: "EDIT_QUANTITY", payload: 1 }),
    decrement: () => dispatch({ type: "EDIT_QUANTITY", payload: -1 }),
    onQuantityInputChange: (e) => {
      dispatch({ type: "SET_QUANTITY", payload: e.target.value })
    }
  }

  return [state, dispatch, helpers]
}

function init (product) {

  const isProductInCart = (product.productId)
  if (isProductInCart) {
    // this is an old product
    return product
  } else {
    // this is a new product
    const cartItemId = Date.now()
    let initializedProduct = Object.assign({}, product,
      {id: cartItemId, productId: product.id, quantity: 1 })
    return initializedProduct
  }
}


function reducer (state, action) {
  switch (action.type) {
    case "LOAD_PRODUCT":
      return action.payload
    case "EDIT_QUANTITY":
      const delta =   Number(action.payload)   || 0
      const current = Number(state.quantity) || 0
      let newQuantity = Math.floor(current + delta)
      if (newQuantity < 1) { newQuantity = 1 }
      return Object.assign({}, state, {
        quantity: newQuantity,
        ariaLive: `changed quantity to ${newQuantity}`
      })
      break;
    case "SET_QUANTITY":
      let targetQuantity =   Number(action.payload)   || 0
      targetQuantity = Math.floor(targetQuantity)
      if (targetQuantity < 1) { targetQuantity = 1 }
      return Object.assign({}, state, {
        quantity: targetQuantity,
        ariaLive: `changed quantity to ${targetQuantity}`
      })
      break;
    case "EDIT_CUSTOMER_CHOICE":
      const picksDelta = action.payload
      const oldPicks = state.customerPicks || {}
      const newPicks = Object.assign({}, oldPicks, picksDelta)
      return Object.assign({}, state, {customerPicks: newPicks})
    case "EDIT_NOTE":
      const note = action.payload
      return Object.assign({}, state, {note})
    default:
      throw new Error("unknown action type: " + action.type)
  }
}

import React, {useState, useEffect} from 'react'
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';


function PaymentRequestButton ({ label, amount, handleTokenEvent, disableOrdering }) {
  const stripe = useStripe();
  const [canMakePayment, setCanMakePayment] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState(false)

  useEffect(function getToken () {
    console.log('getting payment request token')
    if (!stripe) {return}
    setCanMakePayment(false)
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      // requestPayerPhone: true,
      // requestPayerName: true,
      total: {
        label,
        amount,
      },
    });
    setPaymentRequest(paymentRequest)

    paymentRequest.on('token', (response) => {
      const {complete, token, ...data} = response
      console.log('Received Stripe token: ', token);
      console.log('Received customer information: ', data);
      // complete('success');
      handleTokenEvent(response)
    });
    console.log('can make payment inquiry...')
    paymentRequest.canMakePayment().then((result) => {
      console.log('can make?', result)
      setCanMakePayment(!!result);
    }).catch((e) => {
      console.log(e)
    })
  }, [label, amount, stripe])

  return(
    <div className="paymentRequestButton">
      
      {canMakePayment ?
      <div className="quick-checkout">
        <div className="checkout__cash-payment">
          <h2
          style={{
            marginBottom: "1em"
          }}
           aria-label="quick checkout options"
          >Quick Checkout</h2>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
          
              style: {
                // paymentRequestButton: {
                //   theme: 'light',
                //   height: '64px',
                // },
              }
            }}
            className="PaymentRequestButton"
          />
        </div>

        <hr aria-hidden className="checkout__section-divider"/>

      </div>
       : null}
    </div>
  )
}

export default (PaymentRequestButton)

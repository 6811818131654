import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import './AutoMenuNavigator.scss'

export default function AutoMenuNavigator ({subMenus}) {
  const [expanded, setExpanded] = useState(false)
  return(
    <nav
      className="menu-navigation"
      role="navigation"
      aria-label="Menu Categories">
        <NavLink
            exact
            className="category-link"
            to={`/all`}>ALL</NavLink>
                        <span className="link-separator"> | </span>

      {subMenus.map(({id, name, description, disableOrder}) => {
        return(
          [<NavLink
            key={id}
            className="category-link"
            to={`/all/${id}`}>{name && name.en}</NavLink>, 
            <span className="link-separator"> | </span>
          ]
        )
      })}
    </nav>
  )
}

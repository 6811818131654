import React from 'react'

const Price = ({modifier: {price, priceIf}, customerPicks, priceDiv}) => {
  let priceDisplay
  if (Array.isArray(priceIf)) {
    let result = priceIf.find(({match}) => customerPicks[match])
    priceDisplay = result && result.price || null
  } else {
    priceDisplay = price || null
  }

  if (Number(priceDiv)) { priceDisplay = Number(priceDisplay) / Number(priceDiv) }

  return priceDisplay ? <span className="price">${priceDisplay}</span> : null
}

export default Price

import React, {useEffect, useState} from 'react'
import axios from 'axios'
import './Email.scss'

export default function Email ({ orderId, shopId, name }) {
  const [email, setEmail] = useState('')
  const [wantPromos, setWantPromos] = useState('')
  const [submitStatus, setSubmitStatus] = useState(false)
  // const [promos, setPromos] = useState('')

  function handleSubmitEmail (e) {
    e.preventDefault()
    axios.post('https://afa-emails-encrypted.glitch.me/signup', {
      orderId, shopId, name, email
    }).then((res) => {
      const {success} = res.data
      if (success) { setSubmitStatus('success') }
    }).catch((e) => {
      console.log(e)
    })

  }

  return(
    <div className="email-sign-up">
      {/* <h3>Support us by joining our email list:</h3> */}
      
      {!submitStatus && <form action="" onSubmit={handleSubmitEmail}>
        <label htmlFor="email">Join Our Email List: </label>
        <input id="email" required placeholder="" className="email-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
        <input type="submit" value="Subscribe" className="subscribe-button"/>

      </form>}
      {(submitStatus === 'success') && <div className="success">
        <p>Thanks for Subscribing!</p>
      </div>}

      {/* <div id="mc_embed_signup">
      <form action="https://afoodapart.us19.list-manage.com/subscribe/post?u=76734d99f025554f63eeac8d5&amp;id=9c172e58b4" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">
      <div className="mc-field-group">
      	<label for="mce-EMAIL">Email Address  <span className="asterisk">*</span>
      </label>
      	<input type="email" name="EMAIL" className="required email" id="mce-EMAIL"/>
      </div>
      <div className="mc-field-group">
      	<label for="mce-FNAME">First Name </label>
      	<input type="text" name="FNAME" className="" id="mce-FNAME"/>
      </div>
      <div className="mc-field-group">
      	<label for="mce-LNAME">Last Name </label>
      	<input type="text" name="LNAME" className="" id="mce-LNAME"/>
      </div>
	<div id="mce-responses" className="clear">
      		<div className="response" id="mce-error-response" style={{display:"none"}}></div>
      		<div className="response" id="mce-success-response" style={{display:"none"}}></div>
      	</div>
          <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_76734d99f025554f63eeac8d5_9c172e58b4" tabIndex="-1" value=""/></div>
          <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/></div>
          </div>
      </form>
      </div> */}


    </div>
  )
}

import React, { Component } from 'react';
import {injectShopContainer} from '../react/ShopDataContainer'
import {getRestaurantId} from '../utils'
import './how-to-install.scss'
class HowToInstall extends Component {
  componentDidMount() {


    window.addEventListener("orientationchange", () => {
      this.forceUpdate()
    });

  }
  render() {
    window.scrollTo(0, 0)

    if (!this.props.shop) {return null}

    // test device
    var isiPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var isiPad = /iPad/.test(navigator.userAgent) && !window.MSStream;
    var isPortrait = (window.innerHeight > window.innerWidth)
    var isSmallerThanFive = (window.innerHeight < 569) && (window.innerWidth < 569)
    console.log('is iphone SE', isSmallerThanFive)

    return (
      <div className="how-to-install">

        <div className="app-icon-wrapper">
          <div className="app-icon-frame">
            <img className="app-icon" src={`${getRestaurantId()}.png`} alt=""/>
          </div>
        </div>

        {isiPhone && isPortrait && <div className="instructions-image-wrapper">
          <img src="/how-to-install-iphone.png" alt=""/>
        </div>}


        {/* iphone SE landscape */}
        {isiPhone && !isPortrait && isSmallerThanFive && <div
          className="se-portrait-wrapper">
          <img src="/how-to-install-iphone.png" alt=""/>
        </div>}

        {isiPad && <div className="ipad-instructions-image-wrapper">
          <img src="/how-to-install-ipad.png" alt=""/>
        </div>}

        {isiPhone && !isPortrait && !isSmallerThanFive && <div className="ipad-instructions-image-wrapper">
          <img src="/how-to-install-ipad.png" alt=""/>
        </div>}

      </div>
    );
  }
}

export default injectShopContainer(HowToInstall);

import React, {useState} from 'react'
import {Link, useRouteMatch} from 'react-router-dom'
import './AutoMenuNavigatorTwo.scss'

export default function AutoMenuNavigator ({subMenus}) {
  const [expanded, setExpanded] = useState(false)
  let { path, url } = useRouteMatch();
  return(
    <div className="menu-index-wrapper">
      <nav
        className="menu-index"
        role="navigation"
        aria-label="Menu Categories">
        
        <header className="category__header">
    

          <h3
            
            className="category-name">
            <span className="text">Beachwood Cafe</span>
          </h3>

        
        </header>

        {/* <div style={{textAlign: "center", fontSize: "1.5em", margin: "1em 0"}} className="pdf-menu">
          <a href="/public/beachwood/beachwood-dinner.pdf">PDF Dinner Menu (after 4pm)</a>
         </div> */}

        {/* <div className="menu-index-item all-menu-link">
          <Link
            to={`${url}/all`}
            className="index-link-item"
            >
              <h3 className="menu-title">

              All Items
              </h3>
              <p className="description">
                See all categories on the same page
              </p>
              
          </Link>
        </div> */}

        {subMenus.filter(({tag}) => {
              if (tag && (tag.indexOf('hide') !== -1)) {
               return false
              }
              return true
            })
          .map(({id, name, description, disableOrder, products, tag=""}) => {
            const numOfItems = products.length
            const catDescription = products.slice(0,3).map((p) => {
              return p.name && p.name.en
            }).join(', ') + '...'

            let highlight
            if (tag.includes('breakfast')) {
              highlight = "before 4pm"
            }
            if (tag.includes('lunch')) {
              highlight = "after 11am"
            }

            return(
              <div className="menu-index-item">
                <Link
                  to={`${url}/${id}`}
                  className="index-link-item"
                  >
                    {/* {highlight ? <div className="highlight">
                      <span className="highlight-text">{highlight}</span>
                    </div> : null} */}

                    <h3 className="menu-title">

                    {name && name.en} 
                    {/* <span className="num-of-items"> ({numOfItems})</span> */}
                    </h3>
                    {/* <p className="description">
                      {catDescription}
                    </p> */}
                    
                </Link>
              </div>
            )
          })}
      </nav>
    </div>
  )
}

import {round} from 'lodash'

export default function calcDelivery ({
  miles,
  minutes,
  deliverySettings,
  subtotal
}) {
  console.log('subtotal:', subtotal)
  let {
    baseFeeInCents=0,
    maxDistanceInMiles=5,
    feeInCentsPerMile=0,
    baseFeeMiles=0,
    freeDeliveryQualifyingSubtotalInCents,
    useStepFunction=false,
    fiveOverFour=false,
    threeOverThree=false, fiveOverThree, useUberEatsStep
  } = deliverySettings
  miles = Number(miles); minutes = Number(minutes); baseFeeInCents = Number(baseFeeInCents); maxDistanceInMiles = Number(maxDistanceInMiles);
  feeInCentsPerMile = Number(feeInCentsPerMile); baseFeeMiles = Number(baseFeeMiles); freeDeliveryQualifyingSubtotalInCents = Number(freeDeliveryQualifyingSubtotalInCents)
  // console.log('miles'; miles, baseFeeInCents)
  let additionalMiles = 0
  if ((miles - baseFeeMiles) > 0) {
    additionalMiles = (miles - baseFeeMiles)
  }

  // calculate standard base fees
  let deliveryFeeInCents =
    round(
      baseFeeInCents +
      feeInCentsPerMile * additionalMiles
    )

  //TODO: twoguys step functon temporary hotfix
  if (useStepFunction) {
    deliveryFeeInCents = (miles > 5) ? 500 : 295
  }
  if (threeOverThree) {
    deliveryFeeInCents = (miles > 3) ? 300 : 0
  }
  if (fiveOverThree) {
    deliveryFeeInCents = (miles > 3) ? 500 : baseFeeInCents
  }
  if (fiveOverFour) {
    deliveryFeeInCents = (miles > 4) ? 500 : baseFeeInCents
  }

  if (useUberEatsStep) {
    deliveryFeeInCents = (function(){
      if (miles > 4) { return 1400 }
      if (miles > 3) { return 1210 }
      if (miles > 2) { return 1020 }
      if (miles > 1) { return 830 }
      return 640
    })()
  }

  // check if qualify for free
  if ((freeDeliveryQualifyingSubtotalInCents) && (Number(subtotal) * 100 > freeDeliveryQualifyingSubtotalInCents)) {
    deliveryFeeInCents = 0
    console.log('freeeee', freeDeliveryQualifyingSubtotalInCents)
  }
  
  // console.log('fee:', deliveryFeeInCents)
  let isDeliverable = (miles < maxDistanceInMiles)
  if (!isDeliverable) {
    deliveryFeeInCents = 0
  }
  return {
    isDeliverable,
    deliveryFeeInCents
  }
}

import React, { Component } from 'react';
import {round} from 'lodash'
import getEndpoint from '../utils/getEndpoint'


const PERCENTAGES = [
  10, 15, 20
]

class Tips extends Component {
  state = {
    customTipInDollars: '',
    postingTips: false,
    tipsInCents: null,
    tipSuccess: this.props.tips,
  }
  selectTipAmountInCents = (tipsInDollars) => {
    let tipsInCents = tipsInDollars * 100
    this.setState({tipsInCents})
    this.postTips(tipsInCents)
  }
  submitCustomTip = (e) => {
    e.preventDefault()
    let tipsInCents = this.state.customTipInDollars * 100
    this.setState({tipsInCents})
    this.postTips(tipsInCents)
  }
  postTips = async (tipsInCents) => {
    this.setState({postingTips: true})
    const {order_id, r_id} = this.props
    // console.log(tipsInCents)
    try {
      const resp = await fetch(getEndpoint('tips'), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({tipsInCents, order_id, r_id})
      })
      const content = await resp.json()
      if (content.success === true) {
        this.setState({tipSuccess: true})
      }
    } catch (e) {
      if (window.Raven){window.Raven.captureException(e)}
    }
  }
  render() {
    const {invoice} = this.props
    const {tipsInCents, postingTips, tipSuccess} = this.state
    let disableTipSelect = postingTips
    let calculatedPercentages = PERCENTAGES.map(p => {
      return {
      percentage: p,
      calculated: round(invoice * p / 100, 2)
    }})

    return (
      <div className="tips">
        {tipSuccess &&
          <div className="tip-success">
            Thanks for tipping!
          </div>
        }

        {!tipSuccess && <div className="tipping-ui">
          <h2>Tip (100% to Staff/Driver)</h2>
          {calculatedPercentages.map(({calculated, percentage}) => (
            <button
              disabled={disableTipSelect}
              onClick={this.selectTipAmountInCents.bind(this, calculated)}>
              {percentage}%

              ${calculated}
            </button>
          ))}

          <div>
            <form onSubmit={this.submitCustomTip}>
              <label htmlFor="custom-tip">Custom: $</label>
              <input
                type="number"
                step="0.01"
                value={this.state.customTipInDollars}
                onChange={e => this.setState({customTipInDollars: e.target.value})} id="custom-tip"/>
              <input type="submit" className="button" value="tip" disabled={disableTipSelect}/>
            </form>
          </div>
        </div>}

      </div>
    );
  }
}

export default Tips;

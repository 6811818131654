import React from 'react'

const urlmapping = {
  "/": {},
  "/030113-grand-opening-one-year-anniversary-party-beachwood-cafe-los-angeles-ca": { redirect: '/order' },
  "/menus": { redirect: '/menu' },
  "/menus/restaurant": { redirect: '/menu' },
  "/menus/restaurant/breakfast": { redirect: '/menu' },
  "/menus/restaurant/lunch": { redirect: '/menu' },
  "/drinks": { redirect: '/menu' },
  "/menus/catering": { redirect: '/catering' },
  "/menus/catering/breakfast": { redirect: '/catering' },
  "/menus/catering/dinner": { redirect: '/catering' },
  "/menus/catering/lunch": { redirect: '/catering' },
  "/menus/catering/picnic-basket": { redirect: '/catering' },
  "/menus/catering/private-room": { redirect: '/catering' },
  "/about": {},
  "/about/the-cafe": {},
  "/about/interior-design": {},
  "/find-us": {},
  "/about-us/press": {},
  "/about/vendors": {},
  "/history": {},
  "/photos": {},
  "/cafe-rental": {},
  "/the-most-beautiful-coffee-shop-in-every-state-in-america-by-architectural-digest": {},
  "/hospitality-favorites-by-dwell": {},
  "/liza-weil-featured-in-coveteur-at-the-beachwood-cafe": {},
  "/wp-content/uploads/2015/06/beachwood_cafe_catering_menu.pdf": {},
  "/the-best-new-school-diners-in-los-angeles": {},
  "/4-los-angeles-restaurants-not-to-miss": {},
  "/beachwood-cafe-named-by-treksavvy-as-must-do": {},
  "/patti-peck-on-chopped": {},
  "/patti-peck-is-coming-to-a-television-near-you": {},
  "/raven-crow-studio-journal": {},
  "/where-women-cook-magazine": {},
  "/about/the-cafe/beachwood-outside-for-web": {},
  "/about/beachwood-cafe-food-grid-finallr": {},
  "/history/bottle-pom-pom": {},
  "/the-best-new-school-diners-in-los-angeles/bowl": {},
  "/menus/entryway-banner": {},
  "/liza-weil-featured-in-coveteur-at-the-beachwood-cafe/lw": {},
  "/liza-weil-featured-in-coveteur-at-the-beachwood-cafe/liza_weil_7": {},
  "/liza-weil-featured-in-coveteur-at-the-beachwood-cafe/liza_weil_3-835x557": {},
  "/stampington-post": {},
  "/in-search-of-the-next-meal": {},
  "/page-turning-spaces-5-cafes-we-love-to-read-in": {},
  "/091713-new-night-time-delivery-5pm-10pm-beachwood-cafe-los-angeles-ca": {},
  "/best-breakfast-places": {},
  "/best-places-to-eat-in-hollywood": {},
  "/best-weekday-breakfast-places": {},
  "/sleepy-hollywood-diner-gets-a-stylish-wake-up-call": {},
  "/la-times-daily-dish": {},
  "/pure-wow": {},
  "/la-weekly-squid-ink": {},
}
const mappingArray = []
for (let key in urlmapping) {
  urlmapping[key].origin = key
  mappingArray.push(urlmapping[key])
}
export default mappingArray

// import React, {Component} from 'react'
// import {withRouter} from 'react-router-dom'
// class ScrollToTop extends Component {
//   componentDidMount(prevProps) {
//     window.scrollTo(0, 0)
//     // console.log('did update', this.props.location, prevProps.location)
//     // if (this.props.location !== prevProps.location) {
//     //   console.log('scrolling')
//     //   window.scrollTo(0, 0)
//     // }
//   }

//   render() {
    
//     return this.props.children || <div></div>
//   }
// }

// export default withRouter(ScrollToTop)

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
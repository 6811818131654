import React, {Component} from 'react'

import cloudinary from 'cloudinary-core'

var cl = cloudinary.Cloudinary.new({cloud_name: 'dgps1radv', secure: true});

const CDN_ROOT = 'https://d2abh92eaqfsaa.cloudfront.net'
const TO_REPLACE =  'https://res.cloudinary.com/dgps1radv'

class Photo extends Component {
  render() {
    let {url, width, height, alt} = this.props
    let type
    if (url && url.indexOf('http') === 0) {
      type = 'fetch'
    } else {
      type = null
    }
    let src = cl.url(url,
      {width: Number(width), height: Number(height), crop: "fill",  type})

    src = src.replace(TO_REPLACE, CDN_ROOT)
    return(
      <img src={src} alt={alt}/>
    )
  }
}

export default Photo

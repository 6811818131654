import React, { Component } from 'react';

class ModgroupDisplay extends Component {
  componentDidMount() {

  }
  render() {
    let pickedModgroups = this.props.pickedModgroups || []
    return(
      <div className="modgroups-checkout-display">
        {pickedModgroups.map(({name, modifiers=[]}) => {
          return(
            <span className="">
              {/* <span className="group-name">{name}</span> */}
              <div className="">
                {modifiers.map(({name, price}) => (

                    <span className="">{name},</span>

                ))}
              </div>
            </span>
          )
        })}
      </div>
    )
  }
}



export default ModgroupDisplay

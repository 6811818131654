import React, { Component } from 'react';
// import './ModgroupDisplay.css'

class ModgroupDisplay extends Component {
  componentDidMount() {

  }
  render() {
    let pickedModgroups = this.props.pickedModgroups || []
    // console.log(pickedModgroups)
    return(
      <div className="receipt__modgroups">
        {pickedModgroups.map(({name, modifiers=[]}) => {
          if (modifiers.length === 0) {return null}
          return(
            <span className="receipt__modgroup">
              <span className="receipt__group-name">{name}</span>
              <div className="receipt__modifiers">
                {modifiers.map(({name, price}) => (

                    <span className="receipt__modifier">{name}</span>

                ))}
              </div>
            </span>
          )
        })}
      </div>
    )
  }
}



export default ModgroupDisplay

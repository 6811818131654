import React from 'react'
import {useState, useReducer, useEffect, useRef} from 'react'
import {Link, withRouter} from 'react-router-dom'
import './PostAddToCartQuickNav.scss'


function PostAddToCartQuickNav ({ history, productId }) {
  const focusRef = useRef()
  useEffect(function focus () {
    try { window.scrollTo(0,0) } catch (e) { }
    if (focusRef.current) {
      console.log("focusing...")
      focusRef.current.focus()
    }
  }, [])


  function goBack (e) {
    try {e.preventDefault()} catch (e) {}
    try {
      // window['product-id-to-focus'] = productId
    } catch(e) {}
    history.goBack()
  };

  return(
    <div className="post-add-quick-nav product-wrapper">
      <div className="content product">
        <h1 className="text"
          ref={focusRef}
          tabindex={-1}
          >
          Item successfully added to cart.
        </h1>
        <main aria-label="choose next step" className="actions">
          {productId && <a role="button" href="#" onClick={goBack}>back to where I left off</a>}
          <br/>
          <Link role="button" to="/order">back to start of menu</Link>
          <br/>
          <Link role="button" to="/checkout">go to cart</Link>
        </main>
      </div>
    </div>
  )
}

export default withRouter(PostAddToCartQuickNav)

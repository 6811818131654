import cloudinary from 'cloudinary-core'

const CDN_ROOT = 'https://d2abh92eaqfsaa.cloudfront.net'
const TO_REPLACE =  'https://res.cloudinary.com/dgps1radv'

var cl = cloudinary.Cloudinary.new({cloud_name: 'dgps1radv', secure: true});


function makeCloudinaryUrlForPhoto (photo={}, opts={}, imgixGlobalOptions={}) {
  // console.log(imgixGlobalOptions)
  photo = photo || {}
  let {url, alt, type, pid, s:shopId, id, e:ext} = photo
  let {width, height, custom} = opts
  custom = custom || {}

  // new gcs + imgix
  if (type ==='g') {
    const params = Object.assign({
      w: width,
      h: height,
      auto: 'format'
    }, imgixGlobalOptions, custom)


    // console.log(params)

    let qsArray = []
    for (let key in params) {
      if (params[key]) {
        qsArray.push(`${key}=${params[key]}`)
      }
    }
    let qstring = '?' + qsArray.join('&')
    const url = `https://afag.imgix.net/${shopId}/${id}.${ext}${qstring}`
    return url
  }

  let clType = 'fetch'
  if (type === 'cl') {
    clType = null
    url = pid
  }
  if (!url) {return 'no-pic'} // if no image is associated with product
  let src = cl.url(url,
    {width: Number(width || 50), crop: "fill",  type: clType})
  if (height) {
    src = cl.url(url,
      {height: Number(height || 50), crop: "fill",  type: clType})
  }
  if (width && height) {
    src = cl.url(url,
      {height: Number(height || 50),width: Number(width || 50), crop: "fill",  type: clType})
  }
  return src.replace(TO_REPLACE, CDN_ROOT)

}

export default makeCloudinaryUrlForPhoto

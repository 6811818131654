import React from 'react'
import {Link} from 'react-router-dom'
import { Subscribe } from 'unstated';
import CartContainer from '../react/CartContainer'

import getEndpoint from '../utils/getEndpoint'
let CASHBACK_ENDPOINT = getEndpoint('get-cashback')
console.log('cashback endpoint:', CASHBACK_ENDPOINT)

class Cashback extends React.Component {
  state = {
    cashback: false,
  };
  componentDidMount() {
    this.tryFetchAccount({phone: this.props.cart.state.phone})
  }

  tryFetchAccount = async ({phone}) => {
    if (phone && phone.length < 10) { return }
    try {
      const url = `${CASHBACK_ENDPOINT}?r_id=${this.props.shopId}&phone=${phone}`
      console.log(url)
      const response = await fetch(url)
      const {text:{cashback}} = await response.json()
      console.log('cashback:', cashback)
      this.setState({ cashback })
    } catch (e) {
      console.log(e)
    }
  }

  handleApplyCashback = () => {
    this.props.cart.applyCashback(this.state.cashback)
  }

  removeCashback = () => {
    this.props.cart.applyCashback(0)
  }


  render() {

    let cart = this.props.cart


    return (
      <div className="checkout__cashback">

        {(this.state.cashback > 0) && !(cart.state.appliedCashback > 0) && <div className="">
          your cashback: ${this.state.cashback / 100}

          <button
            className="cashback__apply-button"
            onClick={this.handleApplyCashback}>apply to order</button>


        </div>}

        {(cart.state.appliedCashback > 0) && <div>
          ${cart.state.appliedCashback / 100} cashback applied

          <button
            className="cashback__remove-button"
            onClick={this.removeCashback}>remove</button>
        </div>}

      </div>
    )
  }
}



const WrappedCashback = (props) => {
  return (
    <Subscribe to={[CartContainer]} >
      {(cart) => {
        return(
          <Cashback cart={cart} {...props} key={cart.state.phone}></Cashback>
        )
      }}
    </Subscribe>
  )
}

export default WrappedCashback

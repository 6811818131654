import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'
import logEvent from '../utils/logEvent'


const makePhotoSwipeOpenerForProducts = (productId, products) => {

  var pswpElement = document.querySelectorAll('.pswp')[0];
  let index = 0
  var items = products
  .filter((product={}, i) => {
    return(product.photo)
  })
  .map((product={}, i) => {
    if (product.id === productId) {
      // selected product
      index = i
      logEvent(`gallery`, product.name && product.name.en)
    }

    return({
    src: makeCloudinaryUrlForPhoto(product.photo, {width: 1200}),
    msrc: makeCloudinaryUrlForPhoto(product.photo, {width: 600}),
    w: 0, h: 0, id: product.id,
    title: product.name && product.name.en,
    product: product
  })})

  // define options (if needed)
  var options = {
      // optionName: 'option value'
      // for example:
      shareEl: false,
      index: index, // start at first slide
      history: false,
      timeToIdle: 4000
  };



    // Initializes and opens PhotoSwipe
    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.listen('gettingData', function(index, item) {
        if (item.w < 1 || item.h < 1) { // unknown size
        var img = new Image();
        img.onload = function() { // will get size after load
        item.w = this.width; // set image width
        item.h = this.height; // set image height
           gallery.invalidateCurrItems(); // reinit Items
           gallery.updateSize(true); // reinit Items
        }
    img.src = item.src; // let's download image
    }
  });
  gallery.init();
  return gallery
}

export default makePhotoSwipeOpenerForProducts

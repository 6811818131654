export const compose = (...fns) => (...args) => {
  fns.forEach(fn => fn && fn(...args));
};

export const pick = (obj, ...props) => {
  return props.reduce((newObj, prop) => {
    if (obj.hasOwnProperty(prop)) {
      newObj[prop] = obj[prop];
    }
    return newObj;
  }, {});
};

export const geocodeByPlaceId = placeId => {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const OK = window.google.maps.GeocoderStatus.OK;
      geocoder.geocode({ placeId }, (results, status) => {
        if (status !== OK) {
          reject(status);
        }
        resolve(results);
      });
    } catch (e) {
      reject("Could not access maps server. Please reload page and try again.")
    }


  });
};

import React, { Component } from 'react';
import ModgroupDisplay from './ModgroupDisplay'
import {format} from 'date-fns/esm'

function localized (stringOrObject) {
  if (typeof stringOrObject === 'string') {
    return stringOrObject
  } else if (typeof stringOrObject === 'object') {
    return stringOrObject.en || 'n/a'
  } else {
    return 'n/a'
  }
}

class OrderReceipt extends Component {
  render() {
    const order = this.props.order
    // console.log('receitp:', order)

    if (!order) { throw new Error('no order for receipt') }
    let {
      c_at,
      orderNumber,
      items,
      id,
      name,
      phone,
      cartType,
      deliveryTime,
      pickupTime,
      paymentType,
      deliveryAddress,
      deliveryInstructions,
      distanceInMiles,
      unitNumber,
      chargeResult,
      orderAcceptedByShop,
      tags={},
      isCash,
      tips=0,
      totals={}} = order
    const {numberOfMarketPriceItems, totalPrice, discount, invoice, deliveryFeeInCents,
      tax, allServiceFee,
    } = totals

    if(!items) {
      return null
    }

    const scheduledTS = ((cartType === 'DELIVERY') ? deliveryTime : pickupTime)
    const scheduledOrderClass = scheduledTS ? " scheduled" : ""

    return(
      <div
        key={id}
        className={"order-receipt"}>
          

        <div className="cart-type">
          <span className="attr-name">Type: </span>
          <span className="attr-value">{(cartType === 'DELIVERY') ? "Delivery" : "Pick Up"}</span>
        </div>

        {Number(scheduledTS) ?
          <div><div className="attr-name">Due: </div>{format(new Date(Number(scheduledTS) || c_at), 'eeee h:mma')}</div>
          :
          null
        }

        <div className="customer-info">
          <div className="info-row"><span className="attr-name">Name:</span>{name}</div>

          <div className="info-row"><span className="attr-name">Phone:</span>{phone}</div>
        </div>

        {(cartType==='DELIVERY') &&
          <div className="delivery-info">
            <div className="info-row">
              <span className="attr-name">Address: </span><span>{deliveryAddress}</span>
            </div>
            <div className="info-row">
              <span className="attr-name">Apt/Unit: </span><span>{unitNumber}</span>
            </div>
            <div className="info-row">
              <span className="attr-name">Note: </span><span>{deliveryInstructions}</span>
            </div>
            <div className="info-row">
              <span className="attr-name">Distance: </span><span>{distanceInMiles}miles</span>
            </div>
          </div>
        }


        <ol className="order-items-list">
          {items.map((item) => {
            // id is timestamp
            let {id, name, totalPrice, pickedModgroups, note, category, photo, quantity=1} = item
            return(
              <li className="order-item" key={id}>
                {/* <figure className="thumbnail">
                  <img src={makePhotoURL(photo, {width: 100})} alt=""/>
                </figure> */}

                <div className="text">
                  {/* <Schedule type="product" ts={scheduledTS} /> */}
                  {category && <div className="category">{localized(category)}</div>}
                  <div className="order-item-details">
                    <span className="size">{quantity} x&nbsp;</span>
                    <span className="name">{localized(name)} </span>
                    <span className="price">${Math.floor(totalPrice *  100) / 100}</span>
                  </div>

                  <ModgroupDisplay pickedModgroups={pickedModgroups} />

                  {note ? <div className="note">
                    {note}
                  </div> : null}
                </div>
              </li>
            )
          })}
        </ol>

        {totalPrice && <div className="order-totals">
          <span className="label">Subtotal:</span>
          <div className="amount">${totalPrice}</div>
          {(cartType === 'DELIVERY') && <span className="label">Delivery Fee:</span>}
          {(cartType === 'DELIVERY') && <div className="amount">${(deliveryFeeInCents / 100) || 0}</div>}
          {(discount > 0) && <span className="label">Discounts:</span>}
          {(discount > 0) && <div className="amount">${discount || 0}</div>}
          <span className="label">Taxes:</span>
          <div className="amount">${tax}</div>
          <span className="label">Tips:</span>
          <div className="amount">${tips / 100}</div>
          {allServiceFee &&
            <><span className="label">Temp Fee*:</span>
            <div className="amount">${allServiceFee}</div>
            <div><strong>*due to rising costs, a temporary ten percent on subtotal <br/>applies to all orders online or offline. <br/>Thank you for your understanding.
            </strong></div>
            </>
          }
          <div className="divider"></div>
          <span className="label grand-total">Grand Total:</span>
          <div className="amount grand-total">${invoice}</div>
          <span className="label">status:</span>
          <div className="status">{(paymentType === 'CARD') ? 'Prepaid' : 'Not Paid'}</div>
        </div>}

      </div>
    )

  }
}

export default OrderReceipt;

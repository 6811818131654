import React from 'react'
import {withRouter} from 'react-router-dom'
import './default404notfound.scss'

const optOutRoutes = [
  '/order', '/order/',
  '/menu', '/menu/'
]

function Default404NotFound ({location}) {
  if (optOutRoutes.indexOf(location.pathname) !== -1) {
    return(null)
  }
  return(
    <div className="not-found">
      <article className="notice">
        <h1>404, Sorry!</h1>

        <p>We don't have this page anymore.</p>
        <p>See if the links below help:</p>

        <ul>
          <li><a href="/">Home Page</a></li>
          <li><a href="/order">Online Ordering</a></li>
          <li><a href="/menu">Menu</a></li>
        </ul>
      </article>
    </div>
  )
}

export default withRouter(Default404NotFound)
